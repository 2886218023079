@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-banner{
  background-image: url("../images/Banner-Full.png");
  background-size: cover;
  background-position: center;

}

/* Targeting the entire scrollbar */
.my-scrollbar {
  overflow-y: scroll;
}

/* Webkit browsers (Chrome, Safari) */
.my-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the entire scrollbar */
}

.my-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.my-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px; /* Roundness of the thumb */
}

.my-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

/* Firefox */
.my-scrollbar {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}

/* Edge, IE 10+ */
.my-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
