.clickbait-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .clickbait-popup.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .clickbait-popup.closing {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .popup-content {
    flex-grow: 1;
    text-align: center;
  }
  
  .close-button {
    font-size: 20px;
    cursor: pointer;
  }
  